import React, { useEffect, useState } from "react";
import { css, jsx } from "@emotion/react";
import "../styles/index.css";
import {
  HeroSection,
  SessionsSection,
  IntroSection,
  MessageSection,
  LocationSection,
  NoticeSection,
  SponsorSection,
} from "../indexPage/sections";
import { GlobalNavigationBar } from "../components/GlobalNavigationBar";
import SiteHead from "../head";
import { RecoilRoot } from "recoil";

const PageStyle = css`
  padding-bottom: 4rem;
`;

export const Head = () => <SiteHead />;

const IndexPage = () => {
  return (
    <RecoilRoot>
      <div css={PageStyle}>
        <GlobalNavigationBar />
        <HeroSection />
        <IntroSection />
        <SessionsSection />
        <SponsorSection />
        <LocationSection />
        <NoticeSection />
      </div>
    </RecoilRoot>
  );
};

export default IndexPage;
