import React, { useCallback, useEffect, useState } from "react";
import { css, jsx } from "@emotion/react";
import imgHeroBackground from "../../images/hero-bg.jpg";
// import imgExcelconLogoWhite from "../../images/logos/logo-gnb.svg"
import imgHeroLogo from "../../images/logos/main-badge.png";
import { LoginPopup } from "../../components/LoginPopup";
import { MyTicketPopup } from "../../components/MyTicketPopup"
import { useRecoilState } from "recoil";
import { accessTokenState } from "../../accessTokenState";
import { NotSelectedPopup } from "../../components/NotSelectedPopup";

const HeroSectionStyle = css`
  background: url(${imgHeroBackground}) center;
  background-size: cover;
  min-height: 90vh;
  position: relative;
  text-align: center;

  padding-top: 6.9rem;
  padding-bottom: 4.5rem;

  display: flex;
  align-items: center;

  .container {
    position: relative;
    z-index: 1;
    flex-grow: 1;
  }

  h1 {
    margin: 0 0 1.64rem 0;

    img {
      max-width: 390px;
      width: 75%;
    }
  }

  h2 {
    margin-bottom: 2.5rem;
  }

  .catch-phrase {
    font-family: "Pretendared", sans-serif;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: -2px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .price-notice {
    font-weight: 500;
    font-size: 1.25rem;
    margin-top: 20px;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

const InquiryButtonStyle = css`
  display: inline-block;
  margin-top: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 99rem;
  border: none;
  background: var(--color-primary);
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.5);
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  color: white;

  &::visited {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 1.125rem;
    padding: 0.375rem 1.125rem;
  }
`;

export function HeroSection() {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showMyTicketPopup, setShowMyTicketPopup] = useState(false);
  const [showNotSelectedPopup, setShowNotSelectedPopup] = useState(false);

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);

  const onLogin = useCallback((token) => {
    setAccessToken(token);
    setShowMyTicketPopup(true);

    window.localStorage.setItem("excelcon-token", token);
  }, []);

  return (
    <>
      <NotSelectedPopup
        visible={showNotSelectedPopup}
        onClose={() => setShowNotSelectedPopup(false)}
      />
      <LoginPopup
        visible={showLoginPopup}
        onClose={() => setShowLoginPopup(false)}
        onLogin={onLogin}
        onNotSelected={() => setShowNotSelectedPopup(true)}
      />
      <MyTicketPopup visible={showMyTicketPopup} onClose={() => setShowMyTicketPopup(false)} accessToken={accessToken} />
      <section css={HeroSectionStyle}>
        <div className="container">
          <h1>
            <img src={imgHeroLogo} alt="1st Excelcon" />
          </h1>
          <p className="catch-phrase">
            술과 음식이 흐르는
            <br />
            SW메이커들의 네트워킹 파티
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
            {
              accessToken
                ? (<button css={InquiryButtonStyle} onClick={() => setShowMyTicketPopup(true)}>내 티켓 보기</button>)
                : (<button css={InquiryButtonStyle} onClick={() => setShowLoginPopup(true)}>당첨 확인하기</button>)
            }
          </div>
          </div>
        </div>
      </section>
    </>
  );
}
