import React, { useEffect, useState } from "react"
import { css } from "@emotion/react";
import { SectionStyle } from "../../styles/common";

const LocationSectionStyle = css`
  ${SectionStyle};
  background: #222222;
  color: white;
  text-align: center;

  h2 {
    margin-bottom: 2rem;
    font-size: 3rem;

    @media (max-width: 768px) {
      font-size: 1.625rem;
      margin-bottom: .6875rem;
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 5.5rem;
    font-size: 1.375rem;
    font-weight: 200;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .notice {
    margin-bottom: 4rem;
    font-size: 1.375rem;
    font-weight: 200;

    @media (max-width: 768px) {
      font-size: .75rem;
    }
  }

  .map-wrap {
    max-width: 995px;
    height: 558px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.75rem;

    border-radius: 1rem;
    overflow: hidden;

    background: var(--color-primary);

    
    @media (max-width: 768px) {
      height: 400px;
    }
  }

  .root_daum_roughmap {
    max-width: 100%;
  }
`;

export function LocationSection() {
  useEffect(() => {
    new window.daum.roughmap.Lander({
      "timestamp": "1661785492916",
      "key": "2bivo",
      "mapWidth": "995",
      "mapHeight": "558"
    }).render();
  }, []);

  return <>
    <section css={LocationSectionStyle}>
      <div className="container">
        <h2>
          <span className="text-primary-sub">마장동 엑셀플레이스</span>에서<br />
          6월 3일에 만나요!
        </h2>
        <h3>
          서울시 성동구 고산자로 335
        </h3>
        <div className="map-wrap">
          <div id="daumRoughmapContainer1661785492916" className="root_daum_roughmap root_daum_roughmap_landing"></div>
        </div>
        <p className="notice">
          * 별도의 주차 공간은 제공 되지 않습니다.
        </p>
      </div>
    </section>
  </>
}