import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { SectionStyle } from "../../styles/common";
import sponsorBg from "../../images/sponsor-bg.jpg";
// import imgExcelconLogo from "../../images/logos/logo-gnb.svg"
// import imgTwitterPicture from "../../images/twitter_picture.png"

const SponsorSectionStyle = css`
  ${SectionStyle};
  background: url(${sponsorBg}) center;
  background-size: cover;

  .notice-box {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    border-radius: 1rem;
    text-align: center;
  }

  h2 {
    margin: 0 0 1.1875rem 0;
    font-size: 3rem;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 1.625rem;
    }
  }

  p {
    font-size: 1.375rem;
    font-weight: 200;
    margin-bottom: 5.2rem;

    @media (max-width: 768px) {
      max-width: 200px;
      margin: 0 auto 2.2875rem;
      font-size: 1rem;
    }
  }
`;

const InquiryButtonStyle = css`
  display: inline-block;
  padding: 0.625rem 2.25rem;
  border-radius: 100rem;
  border: none;
  background: var(--color-primary);
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.5);
  font-size: 1.375rem;
  line-height: 1;
  text-decoration: none;
  color: white;

  &::visited {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    padding: 0.375rem 1.125rem;
    font-size: 1.125rem;
  }
`;

export function SponsorSection() {
  return (
    <section css={SponsorSectionStyle}>
      <div className="container">
        <div className="notice-box">
          <h2>
            2ND EXCELCON의
            <br />
            후원사를 모집합니다!
          </h2>
          <p>
            트위터 Software Maker들의
            <br />
            화제의 네트워킹 행사 EXCELCON과 함께하세요.
          </p>
          <a css={InquiryButtonStyle} href="/sponsor">
            자세히 보기
          </a>
        </div>
      </div>
    </section>
  );
}
