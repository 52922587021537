import React, { useEffect, useState } from "react"
import { css } from "@emotion/react";
import { SectionStyle } from "../../styles/common";
// import imgExcelconLogo from "../../images/logos/logo-gnb.svg"
import imgTwitterPicture from "../../images/logos/twitter.jpg"

const NoticeSectionStyle = css`
  ${SectionStyle};
  background: #191919;

  .notice-box {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    border-radius: 1rem;
    text-align: center;
  }

  h2 {
    margin: 0;
    font-size: 3rem;
    margin-bottom: 6.5rem;
    font-weight: 600;
    
    @media (max-width: 768px) {
      font-size: 1.625rem;
    }
  }

  .twitter-card-wrap {
    margin-top: 2rem;
    display: flex;
  }

  .twitter-card {
    display: block;
    margin-left: auto;
    margin-right: auto;

    color: inherit;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    border-radius: 1rem;
    padding: 1rem;
    background: black;
    box-shadow: rgb(255 255 255 / 20%) 0px 0px 15px, rgb(255 255 255 / 15%) 0px 0px 3px 1px;

    .picture {
      display: block;
      width: 4rem;
      height: 4rem;
      border-radius: 2rem;
      object-fit: cover;

      margin-bottom: .5rem;
    }

    .twitter-name {
      font-size: 1.125rem;
      font-weight: 700;
    }

    .twitter-id {
      color: rgb(113, 118, 123);
      margin-bottom: .75rem;
    }
  }
`;


export function NoticeSection() {
  return <section css={NoticeSectionStyle}>
    <div className="container">
      <div className="notice-box">
        <h2>
          EXCELCON 트위터 계정에서<br />
          최신 소식을 확인하세요
        </h2>
        <div className="twitter-card-wrap">
          <a href="https://twitter.com/excelcon_day" target="_blank" className="twitter-card">
            <img src={imgTwitterPicture} alt="엑셀콘 로고" className="picture" />
            <div className="twitter-name">EXCELCON / 엑셀콘</div>
            <div className="twitter-id">@excelcon_day</div>
            <div className="twitter-desc">6월 3일 토요일, 마장동 엑셀플레이스에서 만나는 네트워킹 파티!</div>
          </a>
        </div>
      </div>
    </div>
  </section>
}