import React from "react";

import sessionImage1 from "../images/speakers/kanghw0720.jpg";
import sessionImage2 from "../images/speakers/kodingwarrior.jpg";
import sessionImage3 from "../images/speakers/bbvch13531.jpg";
import sessionImage4 from "../images/speakers/heliatalk.jpg";
import sessionImage5 from "../images/speakers/isValidUserId.jpg";
import sessionImage6 from "../images/speakers/ddururiiiiiii.jpg";
import sessionImage7 from "../images/speakers/jtree03.jpg";

export interface SessionData {
  slug: string;
  profileImageUrl: string;
  description: string;
  duration: string;
  time: string;
  floor: 1 | 2;
  presenter: {
    socialMediaAccount: string;
    name: string;
    description: string;
  };
  title: string;
  decoratedTitle: JSX.Element;
}

// description: "",

export const sessionData: SessionData[] = [
  {
    slug: "1",
    title: "Git 커맨드와 있으면 편리한 alias/function",
    decoratedTitle: <>Git 커맨드와 있으면 편리한 alias/function</>,
    profileImageUrl: sessionImage1,
    floor: 1,
    duration: "30",
    time: "18:40 ~ 19:10",
    description: `우리는 Git을 어떻게 사용하고 있나요? 자주 마주치는 상황에서 Git Command를 더 잘 쓸 수 있는 방법과 ProTip! 그리고 있으면 편리한 Alias를 발표를 통해 나누고자 합니다.`,
    presenter: {
      socialMediaAccount: "kanghw0720",
      name: "KangDroid",
      description:
        "이것저것 많이 해보는 주니어 백엔드/DevOps 개발자. 향긋한 커피와 빵을 좋아합니다.",
    },
  },
  {
    slug: "2",
    title: "Neovim으로 생산성 퀀텀점프하기",
    decoratedTitle: <>Neovim으로 생산성 퀀텀점프하기</>,
    profileImageUrl: sessionImage2,
    floor: 2,
    duration: "30",
    time: "18:00 ~ 18:30",
    description: `저는 개발자로서 RoamResearch와 VSCode를 사용하던 중 불의의 사고를 겪었습니다. 사고를 겪은 직후, Neovim을 접하고 나서 저의 개발 도구와 글 작성 도구를 모두 이것으로 전환하게 되었습니다. 그만큼 매력적인 도구인 Neovim을 여러분께 소개하고, 100퍼센트 활용할 수 있는 팁까지 공유하고 싶습니다.`,
    presenter: {
      socialMediaAccount: "kodingwarrior",
      name: "이재열",
      description: `Neovim 에 과몰입한 백엔드 개발자
INTP과 ENFP 사이를 오가는 하이브리드
사색을 좋아하면서 한편으로는 도파민 자극을 좋아합니다`,
    },
  },
  {
    slug: "3",
    title: "저장하고 쌓이기만 하는 아티클을 잘 읽고 관리하는 시스템",
    decoratedTitle: (
      <>저장하고 쌓이기만 하는 아티클을 잘 읽고 관리하는 시스템</>
    ),
    profileImageUrl: sessionImage3,
    floor: 2,
    duration: "20",
    time: "19:50 ~ 20:10",
    description: `매일 많은 글을 보고 나중에 읽기 위해 저장하지만
저장만 하고 다시 읽지 않게 됩니다.
결국 읽지 않은 아티클이 쌓여만 가는데요.

이 문제를 해결하기 위한 저만의 방법을 소개합니다.`,
    presenter: {
      socialMediaAccount: "bbvch13531",
      name: "허규영",
      description: `함수형 프로그래밍을 더 잘 하고 싶은 iOS 개발자.
가장 좋아하는 위스키는 아란 쉐리 캐스크 CS`,
    },
  },
  {
    slug: "4",
    title: "프론트엔드 개발자가 1년 동안 쌓아온 꿀팁 소문 내기",
    decoratedTitle: <>프론트엔드 개발자가 1년 동안 쌓아온 꿀팁 소문 내기</>,
    profileImageUrl: sessionImage4,
    floor: 2,
    duration: "20",
    time: "19:20 ~ 19:40",
    description: `여러분들과 생산성을 높이고 개발자로서 기분 좋은 환경을 조성하는 몇 가지 꿀팁과 도구를 소개하고자 합니다. 쉽게 적용 가능하면서도 효과적인 내용들을 함께 공유하겠습니다.`,
    presenter: {
      socialMediaAccount: "heliatalk",
      name: "헬리아",
      description: `"놀라움을 주고싶은 주니어 프론트엔드 개발자 
VR/AR 회사에서 리액트와 Three.js의 조합에 고군분투하고 있습니다.
사람과 자연과 함께하길 좋아하며 운동과 채소를 좋아합니다."`,
    },
  },
  {
    slug: "5",
    title: "주니어가 회사에서 기회를 얻는 방법",
    decoratedTitle: <>주니어가 회사에서 기회를 얻는 방법</>,
    profileImageUrl: sessionImage5,
    floor: 1,
    duration: "20",
    time: "19:20 ~ 19:40",
    description: `개인의 능력, 팀의 협업, 그리고 회사 전체의 효율성을 향상 시키는 것은 개선의 과정을 통해 이루어집니다. 
작은 개선을 통해 작은 성공을 이루고, 더 큰 기회로 이어질 수 있는 시야를 공유하고 하고자 합니다.`,
    presenter: {
      socialMediaAccount: "isValidUserId",
      name: "1호",
      description: `서버 컴퓨터를 만들다가,
3년차 서버개발자가 되었고,
고양이(걸오)님을 부양하기 위해 열심히 살아가고 있습니다.`,
    },
  },
  {
    slug: "6",
    title: "‘다꾸 장인’ 개발자의 ‘다꾸’ 하는 법",
    decoratedTitle: <>‘다꾸 장인’ 개발자의 ‘다꾸’ 하는 법</>,
    profileImageUrl: sessionImage6,
    floor: 2,
    duration: "20",
    time: "20:20 ~ 20:40",
    description: `5년차 마케터였던 저를 개발자로 성장시켜 준 '다꾸', 단순 취미로만 여겨지는 '다꾸'가 어떻게 저에게 도움이 되었는지, 또 플래너를 더욱 유익하게 활용할 수 있는 방법은 무엇인지 깊이 이야기합니다.`,
    presenter: {
      socialMediaAccount: "ddururiiiiiii",
      name: "뚜루리",
      description: `꾸준함이 재능이 될 수 있다고 믿는 '낙관주의' 개발자 입니다.`,
    },
  },
  {
    slug: "7",
    title: "사이드 프로젝트 빠르게 시작하기",
    decoratedTitle: <>사이드 프로젝트 빠르게 시작하기</>,
    profileImageUrl: sessionImage7,
    floor: 2,
    duration: "20",
    time: "18:40 ~ 19:10",
    description: `백엔드 인프라 없이도 하나의 언어, 하나의 프레임워크만으로 웹 서비스를 만들 수 있습니다! 최근 인기가 많아진 Nextjs와 prisma를 사용해서 백엔드 프레임워크 없이 웹 서비스를 빠르게 구축하는 방법을 공유합니다.`,
    presenter: {
      socialMediaAccount: "jtree03",
      name: "제이제이",
      description: `소프트웨어의 변화를 즐기는 엔지니어
부산 태생 INFJ 남자이며
음식은 국밥 및 육류를 좋아합니다.`,
    },
  },
];
