import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { SectionStyle } from "../../styles/common";
import sectionTitle from "../../images/intro-section-title.svg";
import drinkTagImg from "../../images/intro/tags_drink.svg";
import luckydrawTagImg from "../../images/intro/tags_luckydraw.svg";
import networkingTagImg from "../../images/intro/tags_networking.svg";
import sessionTagImg from "../../images/intro/tags_session.svg";

const IntroSectionStyle = css`
  ${SectionStyle};
  background: #222222;
  color: #f3f3f3;
  text-align: center;

  .excelcon-logo {
    display: block;
    margin: 0 auto 3.625rem;

    @media (max-width: 768px) {
      width: 162px;
    }
  }

  h2 {
    font-size: 3rem;
    margin-bottom: 5.43rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
  }

  p {
    font-size: 1.375rem;
    font-weight: 100;
    line-height: 1.56;
    margin-bottom: 1.56rem;

    @media (max-width: 768px) {
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
    }
  }

  .subduded {
    color: #888888;
    font-size: 1rem;
  }

  .tags {
    margin: 57px auto 0;

    img {
      margin: 0.75rem;
      height: 30px;
    }

    @media (max-width: 768px) {
      margin-top: 46px;
      max-width: 200px;

      img {
        margin: 0.3125rem;
        height: 20px;
      }
    }
  }
`;

export function IntroSection() {
  return (
    <section css={IntroSectionStyle}>
      <div className="container">
        <img
          className="excelcon-logo"
          src={sectionTitle}
          width={323}
          alt="Excelcon"
        />
        <h2>
          EXCELPLACE에서 열리는
          <br />
          <span className="text-primary">Software Maker</span>들의
          <br />
          네트워킹 파티
          <br />
        </h2>
        <p>
          맛있는 음식과 술이 넘치는 공간
          <br />
          EXCEL PLACE에서 함께 모여요!
        </p>
        <p>
          다양한 주제의 발표를 함께 듣고
          <br />
          좋은 사람들과 멋진 시간을 보내세요.
        </p>
        <div className="tags">
          <img src={drinkTagImg} alt="Drink" />
          <img src={networkingTagImg} alt="Networking" />
          <img src={sessionTagImg} alt="Session" />
          <img src={luckydrawTagImg} alt="Lucky Draw" />
        </div>
      </div>
    </section>
  );
}
