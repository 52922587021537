import React from "react";
import { css, jsx } from "@emotion/react";
import { SectionStyle } from "../../styles/common";
import { sessionData } from "../../sessionsPage/sessions";
// import arrowRightCircle from "../../images/arrow_right_circle.png";

const speakerImages = {
  // "1": imgSpeaker1,
  // "2": imgSpeaker2,
  // "3": imgSpeaker3,
  // "4": imgSpeaker4,
  // "5": imgSpeaker5,
  // "6": imgSpeaker6,
  // "7": imgSpeaker7,
};

const SessionsSectionStyle = css`
  ${SectionStyle}
  background: #191919;
  text-align: center;

  h2 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      font-size: 1.625rem;
      margin-bottom: 1.25rem;
    }
  }

  p.description {
    font-size: 1.375rem;
    line-height: 1.636;
    margin-bottom: 7.25rem;
    font-weight: 300;

    @media (max-width: 768px) {
      font-size: 1rem;
      margin-bottom: 4.18rem;
    }
  }

  .session-list {
    display: flex;
    flex-wrap: wrap;

    list-style: none;
    padding: 0;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    @media (max-width: 768px) {
      padding: 24px;
    }

    .session-list-item {
      width: 25%;
      padding: 10px;

      position: relative;

      @media (max-width: 768px) {
        width: 50%;
        padding: 5px;
      }

      > a {
        display: block;
        color: inherit;
        text-decoration: none;
        border-radius: 0.5rem;
        overflow: hidden;
        text-align: left;

        background: #404040;
        border-radius: 0.5rem;

        @media (max-width: 768px) {
          border-radius: 0.1875rem;
        }
      }

      .session-list-item-info {
        padding: 22px 16px;

        @media (max-width: 768px) {
          padding: 12px;
        }
      }

      p {
        margin: 0 0 7px 0;
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.8);

        @media (max-width: 768px) {
          font-size: 0.625rem;
        }
      }

      h4 {
        margin: 0;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.4;
        min-height: 56px;
        word-break: keep-all;

        @media (max-width: 480px) {
          font-size: 1.5rem;
          font-size: 1rem;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .sessions-notice {
      width: calc(50% - 1rem);
      height: 240px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 1rem;
      padding: 1.25rem;

      background: #232323;
      border-radius: 0.5rem;
      position: relative;

      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }

      h4 {
        margin: 0 0 1.25rem 0;
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1.4;

        @media (max-width: 480px) {
          font-size: 1.5rem;
        }
      }

      p {
        margin: 0;
        line-height: 1.4;
        font-size: 1.2rem;

        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }

      .sessions-notice-button {
        position: absolute;
        right: 1.875rem;
        bottom: 1.875rem;

        img {
          display: block;
        }

        @media (max-width: 768px) {
          margin-top: 0.5rem;
          display: block;
          position: static;
          width: 2rem;
          height: 2rem;
          margin-left: auto;
        }
      }
    }
  }
`;

export function SessionsSection() {
  return (
    <section css={SessionsSectionStyle} id="sessions">
      <div className="container">
        <h2>
          소중한 경험을 나눠주실
          <br />
          발표자분들을 소개합니다!
        </h2>
        <p className="description">
          최대 두 개의 세션을 들을 수 있으며, <br />
          티켓 구매 시의 1~3순위 신청에 따라 선착순으로 배정됩니다.
        </p>
        <ul className="session-list">
          {sessionData.map((session) => (
            <li className="session-list-item" key={session.slug}>
              <a href={`/sessions/${session.slug}`}>
                <img src={session.profileImageUrl} alt="" />
                <div className="session-list-item-info">
                  <p>
                    {session.presenter.name} @
                    {session.presenter.socialMediaAccount}
                  </p>
                  <h4>{session.title}</h4>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
